.telepromter{
    opacity: 0.7;
    font-size: 11px;
    font-family: sans-serif;
    margin: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.telepromter p {
    margin: 0;
    color: hsl(0deg 100% 60%);
}
.telepromter .line-out {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: calc(100% - 40px);
}
.telepromter .line-in {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 40px);
    pointer-events: none;
}
.telepromter .ghost-text {
    opacity: 0;
    width: calc(100% - 40px);
}
.text-line-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.text-line-wrapper .line-cursor {
    width: 10px;
    height: 12px;
    background: #ef4747;
    border: none;
    margin: 0;
    margin-left: 3px;
}
.text-line-wrapper .line-cursor.off {
    display: none;
}
