.glitch-text {

}
.glitch-text .layer-0,
.glitch-text .layer-1,
.glitch-text .layer-2 {
    position: absolute;
    margin: auto;
    text-align: center;
    width: 100%;
    display: flex;
}
.glitch-text .layer-0 {
    position: relative;
    z-index: 0;
}
.glitch-text .layer-1 {

    display: none;
}
.glitch-text .layer-1 p,
.glitch-text .layer-1 h1,
.glitch-text .layer-1 h2,
.glitch-text .layer-1 h3 {
    color: #f0f;
}
.glitch-text .layer-2 {

    display: none;
}
.glitch-text .layer-2 p,
.glitch-text .layer-2 h1,
.glitch-text .layer-2 h2,
.glitch-text .layer-2 h3{
    color: #0ff;
}
.glitch-text.glitch .layer-1, 
.glitch-text.glitch .layer-2 {
    display: flex;
}