@font-face {
    font-family: ghost;
    src: url('/public/assets/fonts/Ghost Clan/ghostclan.ttf');
}
@font-face {
    font-family: Silkscreen;
    src: url(/public/assets/fonts/Silkscreen/Silkscreen-Regular.ttf);
}
@font-face {
    font-family: Montserrat;
    src: url(/public/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}
@font-face {
    font-family: SquadOne;
    src: url(/public/assets/fonts/Squada_One/SquadaOne-Regular.ttf);
}

html, body {
    margin: auto;
    background: #1c1c1c;
}

p, h1, h2, h3, li {
    color: white;
}

h1, h2, h3, h4, h5 {
    font-family: 'Ghost';
    font-weight: lighter;
}

.menu {
    top: 0;
    display: flex;
    flex-direction: column;
    margin: auto;
    position: fixed;
    width: 100%;
    z-index: 4;
    background: #0e0e0e;
    height: 69px;
    position: fixed;
}
.menu .menu-content {
    width: 100%;
    margin: auto;
    max-width: 80vw;
    display: flex;
    justify-content: space-between;
}
.menu .menu-items {
    display: flex;
    margin: 25px;
}
.menu .menu-items .menu-item {
    margin: 0 20px;
    cursor: pointer;
}
.menu .menu-container {
    display: flex;
}
.menu .menu-items .menu-item p {
    font-family: 'Montserrat';
    margin: 0;
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 13px;
    pointer-events: none;
}
.menu .mobile-menu {
    display: none;
    margin: auto;
}
.menu .mobile-menu p {
    font-size: 20px;
    color: white;
    margin: auto;
}
.menu .mobile-menu-dropdown {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
}
.menu #menu-title-wrapper {
    display: flex;
}
.menu .short-logo{
    width: 90px;
    margin: auto;
}

#home {
    display: flex;
    flex-direction: column;
}

.introduction-text {
    text-align: center;
    font-family: 'Montserrat';
    max-width: 800px;
    margin: 0px auto;
    font-size: 15px;
}

.intro-tags {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-around;
}
.intro-tags .intro-tag {
    font-family: 'Montserrat';
    font-size: 20px;
    color: #e84042;
    font-weight: bold;
}
.intro-tags hr {
    width: 3px;
    height: 19px;
    border: none;
    background: white;
    margin: auto 10px;
}

.detail-title {
    background: #ee273c;
    padding: 6px 20px;
    max-width: 150px;
    font-family: ghost;
    font-weight: lighter;
    text-align: right;
}

.info-wrapper {
    max-width: 820px;
}
.info-row {
    margin-top: 25px;
}
.info-row .info-list {
    display: flex;
    flex-wrap: wrap;
}
.info-row .info-list ul {
    margin: 0;
    width: 100%;
}
.info-row .info-list ul li:first-child {
    margin-top: 0;
}
.info-row .info-list ul li {
    margin-top: 15px;
}
.info-row .info-list ul li::marker{
    content: '>> ';
    font-family: 'ghost';
    font-size: 20px;
    color: #ee273c;
}
.info-row .info-list h2 {
    font-size: 20px;
    color: #ee273c;
}
.info-row .info-list .project-title p,
.info-row .info-list .list-item-title p {
    color: #ff3a4e;
    font-family: 'ghost';
    font-size: 20px;
    letter-spacing: 6px;
}





#name-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}
#name-wrapper .glitch-text {
    margin: 0;
    margin-right: 15px;
}
#name-wrapper .glitch-text:last-child {
    margin-right: 0;
}



.page-main {
    position: relative;
    margin: 20px 0;
}

.page-heading {
    margin: 20px auto;
}
.page-heading .telepromter .line-in {
    top: unset;
    bottom: 10px;
}
#home .decode {
    margin: 0;
    font-family: ghost;
}

.page-head-decoration {
    position: relative;
    top: 3px;
    display: flex;
    margin: 10px auto;
}
.page-head-decoration .square {
    width: 9px;
    height: 9px;
    background: #ff3e3e;
    border: 0;
    margin: 0;
}
.page-head-decoration .line {
    width: 143px;
    margin: auto;
    margin-left: 20px;
    border: 1px solid #ff3e3e;
}




.page-footer {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: auto auto 20px auto;
    z-index: 2;
    position: relative;
}
.page-foot-decoration {
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    margin: 0;
    margin-bottom: auto;
    margin-right: 20px;
    width: 100%;
}
.page-foot-decoration .square {
    width: 9px;
    height: 9px;
    margin-left: 0;
    margin-right: 9px;
    background: #ff3e3e;
    border: 0;
}
.page-foot-decoration .line {
    width: calc(100% - 18px);
    height: 0px;
    margin: auto;
    margin-left: 0;
    border: 1px solid #ff3e3e;
}

.page-title {
    display: flex;
    position: relative;
    margin: 20px 0;
}
.page-title h1,
.page-title p{
    margin: auto;
    font-size: 24px;
    font-family: ghost;
    color: #ff3e3e;
    font-weight: lighter;
    letter-spacing: 12px;
    margin-left: 0;
}
.page-title .telepromter {
    opacity: 1;
    margin: 0;
}
.page-title .telepromter .line-in {
    bottom: 0;
}
.page-title p {
    min-height: 20px;
}
.page-title hr {
    height: 15px;
    width: 48px;
    background: #ff3e3e;
    border: none;
    margin: auto 0;
    margin-right: 15px;
}
.side-text {
    z-index: 3;
    transform: rotate(90deg);
    position: fixed;
    top: 135px;
    right: -88px;
    font-family: ghost;
    font-size: 61px;
    padding: 6px 6px 6px 100px;
    background: hsl(0deg 100% 60%);
}
.side-text p {
    margin: 0;
    color: hsl(0deg 0% 15%);
    font-weight: lighter;
    font-size: 23px;
}

.eye-candy {
    position: absolute;
}
#diagonal-lines-box {
    position: relative;
    max-width: 409px;
    width: 80%;
    margin: 20px auto;
}
.eye-candy.section-number {
    font-family: 'CodePredators';
    margin: 0;
    top: 37px;
    font-size: 44px;
    font-weight: lighter;
}
.text-deco-0 {
    font-size: 121px;
    top: -100px;
    margin: 0;
    right: -63px;
    font-weight: lighter;
    font-family: 'ghost';
    z-index: 2;
}
#box-of-dots {
    left: -232px;
}
#tech-stack {
    width: 95%;
    margin: auto;
}


.terminal {
    display: flex;
    flex-direction: row;
    border: 1px solid #ff5f5f;
    width: 300px;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    background: #00000054;
    top: unset;
    right: 10px;
    bottom: 10px;
    position: relative;
    right: 0px;
    top: 0px;
    margin-left: auto;
}
.terminal .eye-candy {
    width: 43px;
    margin: 12px;
    position: relative;
}
.terminal .text-readout {
    position: relative;
    width: 100%;
    padding: 10px;
    height: 28px;
    margin: auto;
}
.terminal .telepromter {
    margin: -10px;
    width: 100%;
    height: 100%;
}
#text-readout-a {
    position: relative;
    display: flex;
}
#text-readout-a .telepromter {
    max-width: 450px;
    width: 100%;
    background: #00000054;
    border: 1px solid #ef4747;
    margin: 14px;
    margin-left: 0;
    position: relative;
    padding: 20px;
    overflow: hidden;
    opacity: 1;
}
#bg-terminal-1 {
    height: 400px;
}
#bg-terminal-1 .terminal-window-body{
    height: calc(100% - 30px);
}
#background-0-item-0, 
#background-0-item-1 {
    height: calc(100% - 40px);
}
#background-0-item-0.telepromter .line-in,
#background-0-item-1.telepromter .line-in {
    top: unset;
    bottom: 0;
}

.background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    filter: blur(3px);
    z-index: -1;
}

.hero {
    background: #19191975;
    display: flex;
    flex-direction: column;
    margin: 69px auto 0 auto;
    z-index: 2;
    position: relative;
    min-height: 95vh;
    height: 100%;
}

.landing-container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.main .hero::before {
    border-left: 1px solid hsl(0deg 100% 60%);
    content: "";
    display: block;
    position: absolute;
    min-height: 100vh;
    height: calc(100% + 69px);
    top: -69px;
    left: 10%;
    pointer-events: none;
    opacity: 0.3;
}
.main .hero::after {
    border-left: 1px solid hsl(0deg 100% 60%);
    content: "";
    display: block;
    position: absolute;
    min-height: 100vh;
    height: calc(100% + 69px);
    top: -69px;
    left: 20%;
    pointer-events: none;
    opacity: 0.3;
}
.section-index-container {
    position: absolute;
    bottom: 73px;
    left: 11%;
}
.section-index-container hr {
    height: 6px;
    background: #ff3e3e;
    border: none;
    width: 100%;
}
.section-index-container .section-index {
    font-family: 'SquadOne';
    font-size: 27px;
    margin: 0;
    color: #ff3e3e;
    letter-spacing: 3px;
}

.scroll-down-prompt-container {
    display: flex;
    flex-direction: column;
    margin: 0px auto 20px auto;
    min-width: 50px;
    position: relative;
    width: 50px;
    height: 50px;
}
.scroll-down-arrow {
    rotate: 90deg;
    font-family: 'Silkscreen';
    font-size: 30px;
    color: #ff3636;
    margin: auto;
    line-height: 1;
    position: absolute;
    left: 18px;
}

/* Section: About */
.about {
    background: #090909c2;
    position: relative;
    z-index: 2;
}
.about::before {
    border-left: 1px solid hsl(0deg 100% 60%);
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 10%;
    pointer-events: none;
    opacity: 0.3;
}
.about .about-title {
    font-family: 'Ghost';
    text-align: center;
    font-size: 50px;
    color: #ee273c;
    margin: 50px auto;
}
.about .about-row-container {
    margin: auto;
    max-width: 80vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.about .about-column-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
}
.about .tech-stack-container {
    display: flex;
    flex: 1 1;
    min-width: 480px;
}
.tech-stack-container .terminal-window {
    position: relative;
    width: 100%;
    margin: 50px;
}
.about-info-container {
    margin: 0 50px;
}
.about-info-container .about-bio-text {
    font-family: 'Montserrat';
    margin: 0;
}
.badge-info-container {
    display: flex;
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 50px;
    height: min-content;
}
.badge-info-container img.badge { 
    width: 94px;
    height: 94px;
}
.badge-info-container .hr-wrapper{
    display: flex;
    flex-direction: row;
    max-width: 200px;
    width: 80%;
    margin: auto 0;
}
.badge-info-container .hr-wrapper hr.line{
    display: flex;
    flex-direction: row;
    max-width: 200px;
    width: calc(100% - 30px);
    height: 1px;
    border: none;
    background: #ff3e3e;
    margin: auto;
    margin-left: 0;
    margin-right: 17px;
}
.badge-info-container .hr-wrapper hr.square{
    height: 6px;
    width: 6px;
    background: #ff3e3e;
    border: none;
    margin: 0;
}
.badge-info-container .glitch-text {
    margin: auto;
    font-family: ghost;
    font-weight: lighter;
}
.badge-info-container .layer-0 .decode p {
    color: #ff3e3e;
}
.badge-title-area {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}
.badge-title-area .title-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 250px;
}
.badge-title-area .title-wrapper .glitch-text {
    margin: 0;
    margin-right: 15px;
}
.badge-title-area .title-wrapper .glitch-text:last-child {
    margin-right: 0;
}
.tech-stack-container .terminal-window .terminal-window-body .telepromter .ghost-text,
.about .tech-stack-container .terminal-window .terminal-window-body .telepromter .line-in,
.about .tech-stack-container .terminal-window .terminal-window-body .telepromter .line-out {
    width: 100%;
}
.about .tech-stack-container .terminal-window .terminal-window-body .telepromter .ghost-text p,
.about .tech-stack-container .terminal-window .terminal-window-body .telepromter .line-in p,
.about .tech-stack-container .terminal-window .terminal-window-body .telepromter .line-out p {
    font-size: 15px;
    color: #ee273c;
    font-family: 'Silkscreen';
}
#tech-stack-item-1 .line-in{
    bottom: unset;
    top: unset;
    position: relative;
}
#tech-stack .decode{
    margin-bottom: 20px;
}
#tech-stack .decode p{
    font-weight: lighter;
    font-family: 'ghost';
    color: black;
}
.tech-stack-item  {
    opacity: 1;
    margin: 20px;
}
.tech-stack-item .line-in {
    top: 0;
}

.location {
    max-width: 80vw;
    margin: 50px;
    position: relative;
}

/* Section: Projects */
.projects {
    background: #090909c2;
    position: relative;
    z-index: 2;
}
.projects::before {
    border-left: 1px solid hsl(0deg 100% 60%);
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 10%;
    pointer-events: none;
    opacity: 0.3;
}
.projects .projects-container {
    max-width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.projects .projects-title {
    font-family: 'Ghost';
    text-align: center;
    font-size: 50px;
    color: #ee273c;
    margin: 50px auto 10px auto;
}
.projects-sub-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 800px;
    margin: auto;
    width: 100%;
    justify-content: space-around;
}
.projects-sub-title-container .projects-sub-title {
    font-family: 'Silkscreen';
    font-size: 15px;
    color: #ee273c;
}
.projects-sub-title-container hr {
    width: 3px;
    height: 16px;
    margin: auto 10px;
    background: #ffffffc4;
    border: none;
}
.projects-info-container {
    margin: 50px;
}
.projects .projects-row-container {
    display: flex;
}
.projects .project-container .telepromter.project-title p {
    font-family: 'Silkscreen';
    font-size: 30px;
}
.projects .project-container .telepromter.project-info p {
    font-family: 'Montserrat';
    font-size: 15px;
    color: white;
}

/* Section: Work History */
.work-history {
    position: relative;
    z-index: 2;
}

/* Section: Education */
.education {
    position: relative;
    z-index: 2;
}

/* Section: References */
.references {
    position: relative;
    z-index: 2;
}

/* Menu */
.menu .option {
    cursor: pointer;
    margin: 10px auto;
    min-width: 170px;
    padding: 5px;
    background: none;
    border: none;
    margin-left: 0px;
}
.menu .option:hover {
    background-color: hsl(0deg 100% 60%);
}
.menu .option:hover .layer-0 p {
    color: black;
}

.terminal-window {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 400px;
    z-index: 0;
    border: 1px solid hsl(0deg 100% 60%);
    background: black;
}
.terminal-window-menu {
    width: 100%;
    height: 30px;
    top: 0;
    left: 0;
    border-bottom: 1px solid hsl(0deg 100% 60%);
}
.terminal-window-body {
    height: 100%;
    width: 100%;
}
.terminal-view {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    border: 1px solid red;
}
.terminal-view .terminal-menu {
    height: 30px;
    display: flex;
    background: #000000ed;
    border-bottom: 1px solid #f22b2b;
    max-width: 500px;
    width: 100%;
}
.terminal-view .terminal-menu .terminal-title {
    font-family: monospace;
    letter-spacing: 1px;
    color: #ff2424;
    margin: auto 0 auto 10px;
}
.terminal-view .terminal-body .radar {
    border: none;
}
.terminal-view .terminal-body .telepromter {
    max-width: unset;
    position: relative;
    width: 90%;
    margin: 20px auto;
}



#projects .telepromter,
.info-list .telepromter {
    margin: 0;
    font-size: 15px;
    width: 100%;
    min-height: 30px;
    opacity: 1;
}
#projects .telepromter.project-info p,
.telepromter.list-item-detail p {
    color: white;
}
#projects .telepromter .line-in,
.telepromter .line-in{
    top: 0;
}
#projects li,
.info-list li {
    margin-bottom: 20px;
}
#bg-terminal-0 {
    right: 50px;
    top: 500px;
}
#bg-terminal-1 {
    right: 150px;
    top: 300px;
}

.contact-me {
    display: flex;
    position: relative;
    margin: auto;
    z-index: 2;
    background: #090909c2;
}
.contact-me::before {
    border-left: 1px solid hsl(0deg 100% 60%);
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 10%;
    pointer-events: none;
    opacity: 0.3;
}
.contact-me .contact-me-title {
    margin: 50px auto;
}
.contact-me-title-sub {
    margin-bottom: 30px;
}
.contact-me-title-sub p {
    font-family: 'Montserrat';
    text-align: center;
    max-width: 600px;
    margin: auto;
}
.contact-me .contact-me-title .decode p {
    font-family: 'Ghost';
}
.contact-me .contact-me-form-container {
    width: 100%;
    max-width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.contact-me .contact-me-form-container .contact-me-form {
    margin-bottom: 50px;
}

.contact-me .contact-me-form-container .contact-me-form .input-field {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin: auto auto 20px auto;
}

.contact-me .contact-me-form-container .contact-me-form .input-field.required .input-label::after {
    content: "*";
    margin-left: 5px;
    color: red;
    font-size: 17px;
}

.contact-me .contact-me-form-container .contact-me-form .input-field .input-label {
    font-family: 'Montserrat';
    font-size: 13px;
    text-transform: uppercase;
    width: 100%;
}

.contact-me .contact-me-form-container .contact-me-form .input-field textarea {
    resize: vertical;
    min-height: 200px;
}
.contact-me .contact-me-form-container .contact-me-form .input-field input,
.contact-me .contact-me-form-container .contact-me-form .input-field textarea {
    padding: 10px 20px;
    outline: none;
    border: 1px solid #ee273c;
    background: none;
    color: #ee273c;
    font-family: 'Montserrat';
    font-weight: bold;
}

.submit-message {
    display: flex;
    flex-direction: column;
    margin: auto auto 20px auto;
    cursor: pointer;
    background: none;
    border: 1px solid #ee273c;
    padding: 10px 50px;
}
.submit-message p {
    pointer-events: none;
    margin: auto;
    font-family: 'Silkscreen';
    font-size: 16px;
}

.contact-me-feedback {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin: auto auto 20px auto;
    background: #000000d1;
    border: 1px solid #ee273c;
}

.contact-me-feedback .submit-result {
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat';
    color: #ffffff;
}






















.contact-wrapper {
    max-width: 820px;
}

.contact-wrapper form .input-field {
    margin-bottom: 30px;
}
.contact-wrapper form .input-field:last-of-type {
    margin-bottom: 0;
}
.contact-wrapper form .input-field .input-label {
    font-family: 'ghost';
    color: #ff3a4e;
    margin: 0 auto 14px auto;
}
.contact-wrapper form .input-field input,
.contact-wrapper form .input-field textarea {
    outline: 0;
    border: 0;
    padding: 8px;
    width: 100%;
    max-width: 400px;
    background: #ffffff38;
    color: white;
}

.contact-wrapper form .input-field textarea {
    resize: none;
    height: 200px;
}
.contact-wrapper form .submit-message {
    cursor: pointer;
    border: 2px solid #ff3a4e;
    margin: 20px 0;
    background: none;
}
.contact-wrapper form .submit-message p {
    margin: 6px 12px;
    font-family: 'ghost';
    font-size: 18px;
    font-weight: 100;
    letter-spacing: 4px;
    color: #ff3a4e;
}


.footer {
    display: flex;
    width: 100%;
    height: 100px;
    z-index: 2;
    position: relative;
    background: #0e0e0e;
}
.footer-img-container {
    display: flex;
    width: 100%;
}
.footer-img-container .footer-img {
    margin: auto;
    width: 100px;
}

@media only screen and (max-width: 1360px) {
    .projects .project-container .telepromter.project-title p {
        font-size: 25px;
    }
    .projects .project-container .telepromter.project-info p {
        font-size: 12px;
    }
    .projects-row-container {
        flex-direction: column;
    }
}
@media only screen and (max-width: 900px) {
    .menu .menu-content {
        max-width: 90vw;
    }
    .about .about-row-container {
        max-width: 90vw;
        flex-direction: column;
    }
    .projects-info-container {
        margin: auto;
    }
}
@media only screen and (max-width: 870px){
    .projects-sub-title-container hr {
        display: none;
    }
}
@media only screen and (max-width: 700px) {

    .menu .menu-container {
        position: relative;
    }
    .menu .menu-items {
        display: none;
        position: absolute;
        right: 0;
        top: 61px;
        flex-direction: column;
        background: #0e0e0e;
        margin: 0;
    }
    .menu .menu-items.display {
        display: flex;
    }
    .menu .mobile-menu {
        display: flex;
    }

    .menu .menu-items .menu-item {
        text-align: right;
        margin: 10px 20px;
    }

    .about .about-column-container,
    .about .tech-stack-container {
        max-width: 90vw;
        min-width: unset;
    }
    .about .about-column-container {
        margin-bottom: 50px;
    }
    .badge-info-container{
        margin: auto;
        margin-bottom: 50px;
        max-width: unset;
    }
    .about-info-container {
        margin: auto;
        margin-bottom: 50px;
    }
    .location {
        margin: auto;
        width: 100%;
        max-width: unset;
    }
    .introduction-text {
        max-width: 80%;
    }
    .intro-tags .intro-tag {
        font-size: 15px;
    }
    .intro-tags hr {
        height: 14px;
    }
    .section-index-container {
        bottom: 37px;
        left: 20px;
    }
    #name-wrapper {
        flex-direction: column;
    }
    #name-wrapper .glitch-text {
        text-align: center;
        margin: auto;
    }
    #name-wrapper .glitch-text .decode p{
        font-size: 90px;
    }
    #diagonal-lines-box {
        max-width: 302px;
    }
    .projects-title .glitch-text .decode p,
    .about-title .glitch-text .decode p,
    .contact-me-title .decode p {
        font-size: 80px;
    }
    .tech-stack-container .terminal-window {
        margin: auto;
    }
    .projects .projects-container, 
    .contact-me .contact-me-form-container {
        max-width: 90vw;
    }


    .main .hero::before, 
    .about::before, 
    .projects::before, 
    .contact-me::before {
        left: 15px;
    }
    .main .hero::after {
        left: 80px;
    }
}
@media only screen and (max-width: 600px) {

    .projects-title .glitch-text .decode p,
    .about-title .glitch-text .decode p,
    .contact-me-title .decode p {
        font-size: 70px;
    }

    .decode {
        margin: auto;
    }
    .shortcuts-container .decode {
        margin: auto;
    }
    .landing-center .head {
        padding-top: 130px;
        padding-left: 30px;
    }
}
@media only screen and (max-width: 500px) {
    .projects-title .glitch-text .decode p,
    .about-title .glitch-text .decode p,
    .contact-me-title .decode p {
        font-size: 60px;
    }
}
@media only screen and (max-width: 550px) {
    #name-wrapper .glitch-text .decode p{
        font-size: 80px;
    }
    .intro-tags .intro-tag {
        font-size: 12px;
    }
    .intro-tags hr {
        height: 12px;
    }
}
@media only screen and (max-width: 470px) {
    #name-wrapper .glitch-text .decode p{
        font-size: 70px;
    }
    .intro-tags .intro-tag {
        font-size: 12px;
    }
}
@media only screen and (max-width: 400px) {
    .projects-title .glitch-text .decode p,
    .about-title .glitch-text .decode p,
    .contact-me-title .decode p {
        font-size: 50px;
    }
    .badge-title-area .title-wrapper {
        width: unset;
    }
}
@media only screen and (max-width: 390px) {
    .badge-info-container {
        flex-direction: column;
    }
    .badge-title-area {
        margin: 20px 0;
    }
}
@media only screen and (max-width: 350px) {

    .about .tech-stack-container {
        margin: auto;
    }
    .projects-title .glitch-text .decode p,
    .about-title .glitch-text .decode p,
    .contact-me-title .decode p {
        font-size: 40px;
    }

    #name-wrapper .glitch-text .decode p {
        font-size: 50px;
    }

    .about .about-row-container {
        max-width: 95vw;
        margin: unset;
    }

}
@media only screen and (max-width: 300px) {
    .badge-info-container img.badge {
        width: 80px;
        height: 80px;
    }
    .badge-info-container .decode p {
        font-size: 24px;
    }
}
@media only screen and (max-width: 280px) {
    .intro-tags hr {
        display: none;
    }
}
@media only screen and (max-width: 240px) {
    .intro-tags {
        flex-direction: column;
    }
}
@media only screen and (min-width: 900px) {
    .landing-center .head {
        padding-top: 50px;
    }
    .landing-center {
        width: 599px;
        height: 538px;
        min-height: unset;
        margin: auto;
    }
}