.decode {
    display: flex;
    margin: 0 auto;
}
.decode p,
.decode h1,
.decode h2,
.decode h3 {
    min-width: 15px;
    margin: 0;
    font-size: 33px;
}
.decode.fs-medium p,
.decode.fs-medium h1, 
.decode.fs-medium h2, 
.decode.fs-medium h3 {
    font-size: 20px;
}
.decode.fs-medium-large p,
.decode.fs-medium-large h1,
.decode.fs-medium-large h2,
.decode.fs-medium-large h3 {
    font-size: 30px;
}
.decode.fs-large p,
.decode.fs-large h1,
.decode.fs-large h2,
.decode.fs-large h3  {
    font-size: 100px;
}

@media only screen and (max-width: 700px) {
    .decode.fs-medium p,
    .decode.fs-medium h1, 
    .decode.fs-medium h2, 
    .decode.fs-medium h3 {
        font-size: 15px;
    }
    .decode.fs-medium-large p,
    .decode.fs-medium-large h1,
    .decode.fs-medium-large h2,
    .decode.fs-medium-large h3 {

    }
    .decode.fs-large p,
    .decode.fs-large h1,
    .decode.fs-large h2,
    .decode.fs-large h3  {
        font-size: 40px;
    }
    
}