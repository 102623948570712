.radar {
    max-width: 500px;
    max-height: 320px;
    min-height: 300px;
    width: 100%;
    height: 100%;
    border: 1px solid #f22b2b;
    position: relative;
    overflow: hidden;
}
.radar .x,
.radar .y {
    transition: .5s;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    border-color: #ff3e3e;
    z-index: 3;
}
.radar .x {
    width: 0;
    height: 100%;
}
.radar .y {
    width: 100%;
    height: 0;
}
.coordinates,
.status-readout {
    border: 1px solid #f22b2b;
    position: absolute;
    height: 27px;
    display: flex;
    background: #00000082;
    z-index: 3;
}
.coordinates p,
.status-readout p {
    color: #f22b2b;
    margin: auto;
    margin-left: 15px;
    font-family: monospace;
    font-size: 14px;
}
.coordinates {
    top: 0;
    right: 0;
    width: 74px;
    border-top: none;
    border-right: none;
}
.status-readout {
    bottom: 0;
    left: 0;
    width: 100px;
    border-left: none;
    border-bottom: none;
}

.radar img {
    width: 529px;
    position: absolute;
    top: -74px;
    left: 0%;
}